import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, ImageBackground, Image } from "react-native";
import { useGlobalState } from "../../shared/state";
import CustomTextInput from "../../components/Text/CustomTextInput";
import { I18N } from "../../shared/util";
import { useNavigation } from "@react-navigation/native";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { Icons, background } from "../../assets";
import { Styles } from "../../styles";
import { DatePickerModal } from "react-native-paper-dates";
import { SafeAreaProvider } from "react-native-safe-area-context";
import ScannerCoreService from "../../shared/services/core-services";

const OrthoRegisterModal: React.FC<any> = (route): JSX.Element => {
  const { params } = route.route;
  const [currentScan, setCurrentScan] = useGlobalState("currentScan");
  const [currentUser, setCurrentUser] = useGlobalState("currentUser");
  const [language] = useGlobalState("language");
  const [Colors] = useGlobalState("colors");
  const [isOrthoOrder, setIsOrthoOrder] = useGlobalState("isOrthoOrder");
  const [customerStoreID] = useGlobalState("customerStoreID");
  const [selectedShoe] = useGlobalState("selectedShoe");
  const i18n = I18N(language);
  const navigation = useNavigation();
  const [firstName, setfirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [employeeNumber, setEmployeeNumber] = useState<string>();
  const [dateOfBirth, setdateOfBirth] = useState<Date>();
  const [date, setDate] = useState();
  const [open, setOpen] = useState(false);
  const today = new Date();
  const includeInsole = params && params.INCLUDE_INSOLE;
  const isInsoleOrder = params && params.isInsoleOrder;
  const insoleGtin = params && params.gtin;
  const [firstNameError, setFirstNameError] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");
  const [dateOfBirthError, setDateOfBirthError] = useState<string>("");
  const [submitError, setSubmitError] = useState<boolean>(false);
  useEffect(() => {
    if (currentUser && currentUser.dateOfBirth && currentUser.firstName && currentUser.lastName && isOrthoOrder) {
      setIsOrthoOrder(true);
      navigation.navigate("OrthopedicInsoleOptionsModal");
    }
  }, [currentUser]);

  const onDismissSingle = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = useCallback(
    (params) => {
      setOpen(false);
      setdateOfBirth(params.date);
    },
    [setOpen, setDate]
  );
  const onSubmit = () => {
    let valid = true;
    setSubmitError(false);

    if (!firstName) {
      setFirstNameError(i18n.t("auth.firstNameRequired"));
      valid = false;
    } else {
      setFirstNameError("");
    }

    if (!lastName) {
      setLastNameError(i18n.t("auth.lastNameRequired"));
      valid = false;
    } else {
      setLastNameError("");
    }

    if (!dateOfBirth) {
      setDateOfBirthError(i18n.t("auth.dateOfBirthRequired"));
      valid = false;
    } else {
      setDateOfBirthError("");
    }
    const assignScanToUser = () => {
      ScannerCoreService.postRegistrationWithScan(employeeNumber, currentScan.customer_uuid, false)
        .then()
        .catch((error) => {
          console.log("ERROR ======", error);
        });
    };
    if (valid) {
      if (employeeNumber) {
        ScannerCoreService.postRegistrationWithUserData(employeeNumber, firstName, lastName, dateOfBirth, customerStoreID || null)
          .then((response: any) => {
            setCurrentUser((prevState: any) => ({
              ...prevState,
              employeeNumber,
              firstName,
              lastName,
              dateOfBirth: dateOfBirth.toLocaleDateString(),
            }));
            setCurrentScan((prevState: any) => ({
              ...prevState,
              customer_uuid: response.uuid,
            }));
            assignScanToUser();
            isOrthoOrder ? navigation.navigate("OrthopedicInsoleOptionsModal") : navigation.navigate("Order", { INCLUDE_INSOLE: includeInsole, isInsoleOrder: isInsoleOrder, GTIN: insoleGtin });
          })
          .catch(() => {
            setSubmitError(true);
          });
      } else {
        ScannerCoreService.patchAnonymousUser(currentScan.customer_uuid, firstName, lastName, dateOfBirth, customerStoreID || null)
        .then((response: any) => {
          setCurrentUser((prevState: any) => ({
            ...prevState,
            firstName,
            lastName,
            dateOfBirth: dateOfBirth.toLocaleDateString(),
            uuid: response.uuid
          }));
          isOrthoOrder ? navigation.navigate("OrthopedicInsoleOptionsModal") : navigation.navigate("Order", { INCLUDE_INSOLE: includeInsole, isInsoleOrder: isInsoleOrder, GTIN: insoleGtin });
        })
        .catch(() => {
          setSubmitError(true);
        });
      }
    }
  };

  useEffect(() => {
    if (firstName) {
      setFirstNameError("");
    }

    if (lastName) {
      setLastNameError("");
    }

    if (dateOfBirth) {
      setDateOfBirthError("");
    }
  }, [firstName, lastName, employeeNumber, dateOfBirth]);

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true}>
        <View style={styles.modalWrapper}>
          <View style={styles.formWrapper}>
            <Text style={styles.titleText}>{i18n.t("auth.register")}</Text>
            <Image source={Icons.registerIcon} style={styles.iconContainer} />
            <View style={styles.textWrapper}>
              <CustomTextInput placeholder={"First Name"} errorValidation={firstNameError} parentCallback={(fName) => setfirstName(fName)} />
              {firstNameError ? <Text style={styles.errorText}>{firstNameError}</Text> : null}
            </View>
            <View style={styles.textWrapper}>
              <CustomTextInput placeholder={"Last Name"} errorValidation={lastNameError} parentCallback={(lName) => setLastName(lName)} />
              {lastNameError ? <Text style={styles.errorText}>{lastNameError}</Text> : null}
            </View>
            <View style={styles.textWrapper}>
              <CustomTextInput placeholder={"Employee Number"} errorValidation="" parentCallback={(employNumber) => setEmployeeNumber(employNumber)} />
              {/* {employeeNumberError ? <Text style={styles.errorText}>{employeeNumberError}</Text> : null} */}
            </View>
            <TouchableOpacity onPress={() => setOpen(true)} style={[styles.textWrapper, { paddingTop: 0 }]}>
              <View style={{ display: "flex", flexDirection: "row", width: "100%", height: "100%" }}>
                <View style={[{ marginTop: 0, paddingLeft: "4%", width: "80%", justifyContent: "center" }]}>
                  <Text style={styles.dateText}>{dateOfBirth && dateOfBirth.toLocaleDateString()}</Text>
                </View>
                <View style={{ width: "20%", height: "95%", justifyContent: "center", alignItems: "flex-end" }}>
                  <Image source={Icons.calendarIcon} style={{ width: "65%", height: "65%", resizeMode: "contain" }} />
                </View>
              </View>

              {dateOfBirthError ? <Text style={styles.errorText}>{dateOfBirthError}</Text> : null}
              <SafeAreaProvider>
                <View style={{ justifyContent: "center", flex: 1, alignItems: "center" }}>
                  <DatePickerModal
                    validRange={{ startDate: undefined, endDate: today }}
                    endYear={today.getFullYear()}
                    locale="de"
                    mode="single"
                    saveLabel={i18n.t("SAVE")}
                    visible={open}
                    onDismiss={onDismissSingle}
                    date={date}
                    onConfirm={onConfirmSingle}
                  />
                </View>
              </SafeAreaProvider>
            </TouchableOpacity>
            {submitError ? (
              <View style={styles.errorMessageWrapper}>
                <Text style={[styles.errorText, { textAlign: "center" }]}>{i18n.t(`auth.${employeeNumber ? "employeeNumberExists" : "genericError"}`)}</Text>
              </View>
            ) : null}
            <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color }]} onPress={onSubmit}>
              <Text style={styles.buttonText}>{i18n.t("auth.register")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default OrthoRegisterModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "space-between",
  },
  modalWrapper: {
    flex: 1,
    width: "57%",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "1.6%",
    alignSelf: "center",
  },
  closeIconContainer: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  iconContainer: {
    width: "10%",
    height: "10%",
    resizeMode: "contain",
    alignSelf: "center",
    margin: "2%",
  },
  closeButton: {
    width: "7%",
    height: "12%",
    position: "absolute",
    right: -5,
    top: 0,
    zIndex: 10,
    paddingTop: "3.5%",
    paddingLeft: "2%",
  },
  formWrapper: {
    width: "90%",
    height: "90%",
    padding: "2%",
    alignSelf: "center",
    backgroundColor: "white",
  },
  titleText: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    fontWeight: "900",
    textAlign: "center",
    textTransform: "uppercase",
  },
  textWrapper: {
    width: "80%",
    height: "10%",
    marginTop: "2%",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "2%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    justifyContent: "space-between",
    alignSelf: "center",
  },
  errorMessageWrapper: {
    width: "80%",
    height: "10%",
    marginTop: "2%",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "2%",
    alignSelf: "center",
  },
  button: {
    width: 195,
    height: 42,
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: "2%",
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  dateText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#000",
    textTransform: "uppercase",
    alignSelf: "flex-start",
  },
  errorText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    color: "red",
    paddingLeft: "4%",
  },
});
