import API from '../../api';

// Basic config
const CORE_BASE_URL = globalThis.env.ONEFID_CORE_API;
const apiLevel = "v2/"

// End points
export const loginEndpoint = `${CORE_BASE_URL}${apiLevel}accounts/login/`;
export const registrationEndpoint = `${CORE_BASE_URL}${apiLevel}accounts/register/`;
export const registrationWithScanEndpoint = `${CORE_BASE_URL}${apiLevel}scans/assign-to-customer/`;
export const getUserProfileEndpoint = `${CORE_BASE_URL}${apiLevel}accounts/profile/`;
export const postScanToCoreEndpoint = `${CORE_BASE_URL}${apiLevel}scans/`;
export const userMeasurementEndpoint = `${CORE_BASE_URL}${apiLevel}scans/user/`
export const getUserPLYEndpoint = `${CORE_BASE_URL}${apiLevel}users/`;
export const getUserAccountEndpoint = `${CORE_BASE_URL}${apiLevel}users/`;
export const updateUserOriginProfileEndpoint = `${CORE_BASE_URL}${apiLevel}accounts/profile/`
export const addEmailToUserWithEmployeeNumber = `${CORE_BASE_URL}${apiLevel}users/by-employee-number/`
export const sendEmailToSetPasswordApi = `${CORE_BASE_URL}${apiLevel}send-email/`
export const scanDevices = `${CORE_BASE_URL}${apiLevel}scan-devices/`


const postScan = (scanDATA: any, partnerToken: string) => 
 API.post(postScanToCoreEndpoint, scanDATA, {
    timeout: 120000,
    headers: {
      Authorization: `Token ${partnerToken}`,
      'Accept-Encoding': 'gzip'
    }
  })

const postLogin = async (username: string, password: string) => API.post(loginEndpoint, { email: username, password })

const postRegistration = async (email: string, setTermsAndConditions: boolean) => {
  const data = email.includes('@') ? { email: email } : { employee_number: email }
  return API.post(registrationEndpoint, { ...data, terms_and_conditions_accepted: setTermsAndConditions })
};

const postRegistrationWithUserData = async (employeeNumber: string, firstName: string, lastName: string, dateOfBirth: Date, createdBy?: string) => {
  const formattedDateOfBirth = dateOfBirth.toLocaleDateString("en-GB", { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/\//g, '-');
  const [day, month, year] = formattedDateOfBirth.split('-');
  const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  const data = { employee_number: employeeNumber,
    first_name: firstName,
    last_name: lastName,
    birth_year: dateOfBirth.getFullYear(),
    birth_date: formattedDate,
    ...(createdBy && { partner: createdBy })
  }
  
  return API.post(registrationEndpoint, { ...data, terms_and_conditions_accepted: true })
};

const postRegistrationWithScan = async (identifier: string, customer_uuid: string, isEmail: boolean) => {
  const data = isEmail ? { email: identifier, customer_uuid } : { employee_number: identifier, customer_uuid };

  return API.post(registrationWithScanEndpoint, data);
}

const patchAnonymousUser = async (uuid: string, firstName: string, lastName: string, dateOfBirth: Date, createdBy?: string) => {
  const formattedDateOfBirth = dateOfBirth.toLocaleDateString("en-GB", { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/\//g, '-');
  const [day, month, year] = formattedDateOfBirth.split('-');
  const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  const partnerNameCheck = createdBy === "elten" ? "Elten" : createdBy;
  const data = { 
    first_name: firstName,
    last_name: lastName,
    birth_date: formattedDate,
    ...(createdBy && {created_by_partner: partnerNameCheck})
  };

  return API.patch(`${getUserAccountEndpoint}by-uuid/${uuid}`, data);
}

const fetchUserProfile = (userToken: string) => API.get(`${getUserProfileEndpoint}`, { headers: { Authorization: `Token ${userToken}` } })

const updateUserOrigin = (userToken: string, data: string) => API.put(updateUserOriginProfileEndpoint, { continent: data }, { headers: { Authorization: `Token ${userToken}` } })

const updateUserBirthDate = (userToken: string, data: string) => API.put(updateUserOriginProfileEndpoint, { birth_date: data }, { headers: { Authorization: `Token ${userToken}` } })

const fetchUserAccount = (email: string, userToken?: string) => {
  const url = email.includes('@') ? `${getUserAccountEndpoint}${email}/` : `${getUserAccountEndpoint}by-employee-number/${email}/`
  const config = userToken ? { headers: { Authorization: `Token ${userToken}` } } : undefined
  return API.get(url, config)
};

const fetchUserMeasurements = (email: string, userToken?: string) => {
  const config = userToken ? { headers: { Authorization: `Token ${userToken}` }, timeout: 30000 } : undefined
  return API.get(`${userMeasurementEndpoint}${email}/measurements/`, config)
}

const fetchUserMesh = (email: string, side: string, condition: string, partnerToken: string, userToken?: string) => {
  const url = email.includes('@') ? `${getUserPLYEndpoint}${email}/unprocessed-mesh/${side}/${condition}/` : `${getUserPLYEndpoint}by-employee-number/${email}/unprocessed-mesh/${side}/${condition}/`
  return API.get(url,
    {
      headers:
      {
        Authorization: `Token ${userToken ? userToken : partnerToken}`,
      }
    })
}

const postRegisterToNewsletter = (email: string) => {

  const formData = new FormData();
  formData.append('email', email);
  formData.append('p[12]', '12');
  formData.append('from', '219');
  formData.append('tags', 'IMPORT: api OneFid');

  return API.post('https://shoepassion98992.api-us1.com/admin/api.php?api_key=88b156732678a1ac23b48ffaa3a70850cae88189850cff9c4e132a8b1969c5848a756ebe&api_action=contact_add&api_output=serialize',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    }
  )
}

const patchUserEmailByEmployeeNumber = (employeeNumber: string, email: string, termsAndConditions: boolean, userToken: string) => {
  const url = `${addEmailToUserWithEmployeeNumber}${employeeNumber}`
  
  return API.patch(url, {email: email, terms_and_conditions_accepted: termsAndConditions}, { headers: { Authorization: `Token ${userToken}` } })
}

const getScanDeviceInfo = async (deviceID: string) => {
  return API.get(`${scanDevices}${deviceID}/`);
}


const ScannerCoreService = { postScan, postLogin, fetchUserProfile, postRegistration, postRegistrationWithUserData, patchAnonymousUser, postRegistrationWithScan, fetchUserMeasurements, fetchUserAccount, updateUserOrigin, updateUserBirthDate, fetchUserMesh, postRegisterToNewsletter, patchUserEmailByEmployeeNumber, getScanDeviceInfo };

export default ScannerCoreService;
