import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Platform } from "react-native";
import { Icons } from "../../../assets";
import Button from "../generic-button/Button";
import { useGlobalState } from "../../../shared/state";
import { useNavigation } from "../../../lib/hooks/useNavigation";
import CircleButton from "../generic-button/CircleButton";

const Auth: React.FC = (): JSX.Element => {
  const [currentUser] = useGlobalState("currentUser");
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [userToken] = useGlobalState("userToken");
  const [language] = useGlobalState("language");
  const [currentScan] = useGlobalState("currentScan");
  const [currentScanner] = useGlobalState("currentScanner");
  const [Colors] = useGlobalState("colors");

  const ButtonColor = currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "onefid" ? Colors.mainColorDark.color : Colors.mainColorLight.color;

  const [userInfo, setUserInfo] = useState<string | undefined>(undefined);

  const navigation = useNavigation();
  const modalName: string = currentMeasurements ? "Register" : "Login";
  const navigateTo: string = Platform.OS === "web" ? modalName : currentMeasurements && navigation.state.routeName.includes("Measurement") ? `${modalName}MeasurementModal` : `${modalName}Modal`;

  useEffect(() => {
    if (currentUser)
      setUserInfo(
        userToken
          ? currentUser.firstName.length > 0 && currentUser.lastName.length > 0
            ? `${currentUser.firstName} ${currentUser.lastName}`
            : currentUser.email
          : currentUser.employeeNumber
          ? currentUser.employeeNumber
          : undefined
      );
    if (currentUser && currentUser.firstName.length > 0 && currentUser.lastName.length > 0) {
      setUserInfo(`${currentUser.firstName} ${currentUser.lastName}`)
    }
    if (currentScanner && currentScanner.displayScanID && !currentUser && currentScan) setUserInfo(currentScan.customer_uuid);
    if (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "ssv" && currentUser) setUserInfo(`${currentUser.firstName} ${currentUser.lastName}`);
    if (!currentUser && !currentScan) setUserInfo(undefined);
  }, [currentUser, currentScan]);

  return (
    <View style={styles.container}>
      {userInfo ? (
        <View style={styles.wrapper}>
            <CircleButton
              color={ButtonColor}
              icon={language === "en" ? Icons.transparentUserIconEN : Icons.transparentUserIconDE}
              navigateTo={currentUser ? "Logout" : navigateTo}
              deactivated={currentScanner ? false : true}
            />
          <View style={styles.logoutTextContainer}>
            <Text style={styles.logoutText}>{userInfo}</Text>
          </View>
        </View>
      ) : (
        <CircleButton icon={language === "en" ? Icons.loginIconTransparentEN : Icons.loginIconTransparentDE} color={ButtonColor} navigateTo={navigateTo} />
      )}
    </View>
  );
};

export default Auth;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
  },
  wrapper: {
    alignItems: "center",
    flexDirection: "row",
    height: "100%",
    width: "100%",
  },
  circleIconContainer: {
    height: "100%",
    width: "100%",
    resizeMode: "contain",
  },
  logoutTextContainer: {
    width: "100%",
    height: "25%",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: "-20%",
    marginLeft: "-5%",
    backgroundColor: "#ffffff",
    zIndex: -1,
    borderRadius: 30,
    borderWidth: 0.5,
  },
  logoutText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 9,
    color: "#000000",
    alignSelf: "center",
    textAlign: "center",
  },
});
