import React, { useState, useEffect } from "react";
import { ImageBackground, StyleSheet, ActivityIndicator, Image, Text, TouchableOpacity, View } from "react-native";
import { useNavigation, useIsFocused, useFocusEffect } from "@react-navigation/native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { background, Icons } from "../../assets";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { useGlobalState } from "../../shared/state";
import { getValidIndex, groupByKey, I18N } from "../../shared/util";
import { Styles, Fonts } from "../../styles";
import CategoryCard from "./CategoryCard";
import { CategoryCardDTO } from "../../shared/model/types";
import I18n from "../../i18nSets/i18n";
import { Gender } from "../../shared/model/enums";
import { fetchProducts, processAndFilterProducts } from "./Utils";
import { insoleCategories } from "../InsoleRecommendation/InsoleRecommendation";

const PreFilterCatalog: React.FC<any> = (route): JSX.Element => {
  const { params } = route.route;
  const [language] = useGlobalState("language");
  const [matchedProducts, setMatchedProducts] = useGlobalState("matchedProducts");
  const [currentScanner] = useGlobalState("currentScanner");
  const [currentUser] = useGlobalState("currentUser");
  const [currentScan] = useGlobalState("currentScan");
  const [newScanState] = useGlobalState("newScanState");
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [initialMatchedProducts, setInitialMatchedProducts] = useGlobalState("initialMatchedProducts");
  const [categoryCards, setCategoryCards] = useState<any>([]);
  const [Colors] = useGlobalState("colors");
  const [, setIsOrthoOrder] = useGlobalState("isOrthoOrder");
  const [toggleOrthoInsoleCheckBox, setToggleOrthoInsoleCheckBox] = useState(false);
  const [toggleShoeFittingCheckBox, setToggleShoeFittingCheckBox] = useState(false);
  const [toggleFullCatalogCheckBox, setToggleFullCatalogCheckBox] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);
  const [fullCatalog, setFullCatalog] = useState<any>([]);

  const insoleCategoryIndex = currentMeasurements
  ? Math.min(
      getValidIndex(currentMeasurements.measurements[0].insole_recommendation),
      getValidIndex(currentMeasurements.measurements[1].insole_recommendation)
    ) === Infinity
    ? 1 // If both indices are -1 return 1
    : Math.min(
        getValidIndex(currentMeasurements.measurements[0].insole_recommendation),
        getValidIndex(currentMeasurements.measurements[1].insole_recommendation)
      )
  : 1;
  const gender = currentUser ? currentUser.gender : newScanState.gender === "male" ? "m" : "f";
  const genderForInsoleFilter = gender === "f" ? "female" : "unisex";
  const recommendedInsoleCategory = insoleCategories[insoleCategoryIndex];
  const matchedInsole = currentScanner.insoleRecommendation && currentScanner.insoleRecommendation.insoles[genderForInsoleFilter] && currentScanner.insoleRecommendation.insoles[genderForInsoleFilter][recommendedInsoleCategory];
  const i18n = I18N(language);
  const navigation = useNavigation();
  const isFocused = useIsFocused();

  const nav = (category: CategoryCardDTO, productsToShow: any) => {
    // Update the matchedProducts state
    setMatchedProducts(productsToShow);

    if (category.attributes) {
      navigation.push("CatalogTree", { category: category, matchedInsole: matchedInsole });
    } else {
      navigation.navigate("CatalogProducts", { categoryID: category.id });
    }
};

  useEffect(() => {
    if (params && params.isOrthoOrder === false) {
      setIsOrthoOrder(false);
    }
  }, [params]);

  useFocusEffect(
    React.useCallback(() => {
      // This code runs when the screen is focused/initialized
      setMatchedProducts(initialMatchedProducts)

      // re enable when cleanup is needed if screen loses focus
      // return () => {
      //   // This code runs when the screen loses focus
      //   console.log('Screen is unfocused');
      // };
    }, [])
  );

  const resetFilters = () => {
    toggleOrthoInsoleCheckBox && setToggleOrthoInsoleCheckBox(!toggleOrthoInsoleCheckBox);
    toggleShoeFittingCheckBox && setToggleShoeFittingCheckBox(!toggleShoeFittingCheckBox);
  }

  useEffect(() => {
    if (toggleFullCatalogCheckBox) {
      setIsLoadingProducts(true);
      var gender = newScanState.gender === Gender.FEMALE ? "f" : "m";
      var customer_uuid = currentScan ? currentScan.customer_uuid : currentUser.uuid;
      var safetyClass = (currentUser && currentUser.safetyClass) || undefined;
      // hardcoded values to get full catalog of elten
      fetchProducts(customer_uuid, "loose",gender, "elten").then((response: any) => {
        processAndFilterProducts(
          response,
          setFullCatalog,
          currentScanner["attribute_name"],
          safetyClass,
          "elten",
          undefined,
          undefined,
          () => {
            setIsLoadingProducts(false);
          }
        )
      })
    } else if (initialMatchedProducts !== null) {
      setMatchedProducts(initialMatchedProducts);
    }
  }, [toggleFullCatalogCheckBox]);
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  
  useEffect(() => {
    // Filtering products
    let productsToShow;
    if (fullCatalog && toggleFullCatalogCheckBox) {
      productsToShow = fullCatalog;
    } else {
      productsToShow = matchedProducts;
    }
  
    if (toggleOrthoInsoleCheckBox) {
      productsToShow = productsToShow.filter(
        (product) => product.products.suitableOrthoInsole
      );
    }
  
    if (toggleShoeFittingCheckBox) {
      productsToShow = productsToShow.filter(
        (product) => product.products.suitableOrthoAdaption
      );
    }
    console.log("# products after filters ====", productsToShow && productsToShow.length)
    setFilteredProducts(productsToShow);
    // Category Card Rendering
    setCategoryCards([]);
    if (productsToShow && productsToShow.length > 0) {
      currentScanner.catalog.categories.forEach((category, index) => {
        for (let categoryID of Object.keys(groupByKey(productsToShow, "id"))) {
          if (categoryID === category.id) {
            setCategoryCards((prevState) => [
              ...prevState,
              <CategoryCard
                key={index}
                CategoryCard={category}
                onPress={() => nav(category, productsToShow)} 
              />,
            ]);
            break;
          }
        }
      });
    }
    // toggleFullCatalogCheckBox && 
    matchedInsole && 
    setCategoryCards((prevState) => [
      ...prevState,
      <TouchableOpacity key="accessories" style={styles.touchContainer} activeOpacity={0.6} onPress={() => navigation.push("AccessoriesCatalog", { insoles: matchedInsole})}>
        <View style={Styles.card}>
          <View style={styles.categoryCard}>
            <Image source={{ uri: Icons.soleIcon }} style={styles.imageContainer} />
          </View>
          <View style={styles.categoryCardTextWrapper}>
            <Text style={styles.categoryCardText}>{I18n.t("accessoriesCatalogTitle", { locale: language })}</Text>
          </View>
        </View>
      </TouchableOpacity>
    ]);
  }, [
    fullCatalog,
    matchedProducts,
    toggleShoeFittingCheckBox,
    toggleOrthoInsoleCheckBox,
    toggleFullCatalogCheckBox
  ]);

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true} title={i18n.t("catalog")}>
        <View style={styles.contentWrapper}>
          {filteredProducts && filteredProducts.length === 0 && !isLoadingProducts && (
            <View style={styles.noProductsWrapper}>
              <Image source={Icons.NotFoundImage} style={{ width: "50%", height: "50%", resizeMode: "contain", alignSelf: "center" }}></Image>
              <Text style={styles.loadingText}>{i18n.t("noProductFoundMessage")}</Text>
              <TouchableOpacity style={Styles.submitButtonLight} onPress={() => navigation.navigate("NewScanGenderSelection", { resetState: true })}>
                <Text style={[styles.buttonText, { ...Colors.black }]}>{i18n.t("new scan")}</Text>
              </TouchableOpacity>
              {currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "elten" && (
                <TouchableOpacity style={[Styles.submitButtonLight, { backgroundColor: Colors.mainColorDark.color }]} onPress={() => resetFilters()}>
                  <Text style={[styles.buttonText, { ...Colors.white }]}>{i18n.t("resetFilter")}</Text>
                </TouchableOpacity>
              )}
            </View>
          )}
          {filteredProducts && filteredProducts.length > 0 && !isLoadingProducts && (
            <>
              <View style={styles.cardWrapper}>{categoryCards.length > 0 && categoryCards}</View>
              {/* Extra filtering for ELTEN */}
              {currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "elten" && (
                <View style={styles.filtersWrapper}>
                  <View style={styles.checkboxWrapper}>
                    <BouncyCheckbox
                      innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                      fillColor={Colors.mainColorLight.color}
                      isChecked={toggleOrthoInsoleCheckBox}
                      onPress={() => setToggleOrthoInsoleCheckBox(!toggleOrthoInsoleCheckBox)}
                      text={I18n.t("orthopedicInsole", { locale: language })}
                      textStyle={styles.checkBoxText}
                    />
                  </View>
                  <View style={styles.checkboxWrapper}>
                    <BouncyCheckbox
                      innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                      fillColor={Colors.mainColorLight.color}
                      isChecked={toggleShoeFittingCheckBox}
                      onPress={() => setToggleShoeFittingCheckBox(!toggleShoeFittingCheckBox)}
                      text={I18n.t("shoeFitting", { locale: language })}
                      textStyle={styles.checkBoxText}
                    />
                  </View>
                  <View style={styles.checkboxWrapper}>
                    <BouncyCheckbox
                      innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                      fillColor={Colors.mainColorLight.color}
                      isChecked={toggleFullCatalogCheckBox}
                      onPress={() => setToggleFullCatalogCheckBox(!toggleFullCatalogCheckBox)}
                      text={I18n.t("fullCatalog", { locale: language })}
                      textStyle={styles.checkBoxText}
                    />
                  </View>
                </View>
              )}
            </>
          )}
          {isLoadingProducts && (
            <View style={styles.loadingWrapper}>
              <Text style={styles.loadingText}>{i18n.t("loadProductMessage")}</Text>
              <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
            </View>
          )}
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default PreFilterCatalog;

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
    marginTop: "2.2%",
    alignItems: "center",
  },
  cardWrapper: {
    width: "81%",
    height: "75%",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: "0.4%",
    paddingLeft: "0.2%",
  },
  filtersWrapper: {
    width: "25%",
    height: "25%",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: "0.4%",
    paddingLeft: "0.2%",
    alignSelf: "baseline",
    marginLeft: "10%",
  },
  titeText: {
    ...Fonts.textHuge,
    ...Fonts.center,
    textTransform: "uppercase",
  },
  loadingText: {
    fontFamily: "Montserrat-SemiBold",
    fontSize: 19,
    fontWeight: "600",
    textAlign: "center",
    alignSelf: "center",
  },
  noProductsWrapper: {
    width: "100%",
    height: "65%",
    justifyContent: "space-around",
    alignSelf: "center",
  },
  loadingWrapper: {
    width: "100%",
    height: "35%",
    justifyContent: "space-around",
    alignSelf: "center",
  },
  buttonText: {
    ...Fonts.mRegular,
    ...Fonts.size12,
    ...Fonts.center,
    textTransform: "uppercase",
  },
  checkboxWrapper: {
    width: "95%",
    flexDirection: "row",
  },
  checkBoxText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 18,
    color: "#000000",
    fontWeight: "300",
    textDecorationLine: "none",
  },
  imageContainer: {
    flex: 1,
    height: undefined,
    width: undefined,
    resizeMode: 'contain'
  },
  touchContainer: {
    height: '49%',
    width: '33%',
    backgroundColor: 'transparent',
    paddingTop: 2,
    paddingLeft: 2
  },
  categoryCard: {
    height: '70%',
    backgroundColor: 'white'
  },
  categoryCardTextWrapper: {
    height: '30%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  categoryCardText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 16,
    textTransform: 'uppercase'
  }
});
