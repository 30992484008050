import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View, Text, Platform } from 'react-native';
import I18n from '../../i18nSets/i18n';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { Styles } from '../../styles';
import { greenDot, Icons, redDot } from '../../assets';
import SizeScale from '../../components/SizeScale/SizeScale';
import ProductService from '../../shared/services/products-service';

export type ProductCardDTO = {
  id?: number
  name?: string
  brand?: string
  gtin?: string
  price?: number
  categoryName?: string
  salePrice?: any
  size: Size
  image_url?: string
  thumb_url?: string
  description?: string
  last_width_category?: number
  width_name?: string
  msi?: boolean
  leading_system?: string
  stock?: number
  mpn?: string
  insoleSizes?: any
  isInsole?: boolean
};

export type Size = {
  eu?: number
  uk?: number
  us?: number
  secondary_eu?: number
  secondary_uk?: number
  secondary_us?: number
}

const ProductCard: React.FC<ProductCardDTO> = (product: ProductCardDTO): JSX.Element => {
  const [, setModalIsDisplayed] = useGlobalState('modalIsDisplayed');
  const [currentScanner] = useGlobalState('currentScanner');
  const [Colors] = useGlobalState('colors');

  const navigation = useNavigation();
  const primarySize: any = product.leading_system === 'eu' ? product.size.eu : product.leading_system === 'uk' ? product.size.uk : product.size.us
  const secondarySize: any = currentScanner.enableSecondarySize || !('enableSecondarySize' in currentScanner)
    ? product.leading_system === 'eu'
      ? product.size.secondary_eu
      : product.leading_system === 'uk'
        ? product.size.secondary_uk
        : product.size.secondary_us
    : undefined
  const price: any = product.price || undefined
  const salePrice: any = product.salePrice || undefined

  const openProductDetailModal = (data: ProductCardDTO) => {
    navigation.navigate(Platform.OS === 'web' ? 'ProductDetail' : 'ProductDetailModal', { product: data });
    setModalIsDisplayed(true);
  }

  return (
    <TouchableOpacity style={styles.touchContainer} activeOpacity={0.6} onPress={() => openProductDetailModal(product)}>
      <View style={Styles.card}>
        <View style={styles.productCard}>
          {product.msi && <Image source={Icons.customizeIcon} style={styles.customizeIcon}></Image>}
          <Image source={Icons.soleIcon} style={styles.soleIcon}></Image>
          <Text style={styles.soleIconText}>{product.last_width_category}</Text>
          <Image source={{ uri: product.thumb_url ? product.thumb_url : product.image_url }} style={styles.imageContainer} />
        </View>
        <View style={styles.productCardDetails}>
          {product.name != undefined && <Text adjustsFontSizeToFit style={styles.productCardNameText} >{product.name}</Text>}
          {product.width_name != undefined && <Text style={styles.widthNameText}>{product.width_name}</Text>}
          {product.price != undefined &&
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.productCardPriceText}>{`${I18n.t('price')}: `}</Text>
              {salePrice && price && salePrice < price ?
                <>
                  <Text style={[styles.productCardPriceText, { textDecorationLine: 'line-through' }]}>{`${price}€ `}</Text>
                  <Text style={[styles.productCardPriceText, { color: 'red' }]}>{`${salePrice}€`}</Text>
                </>
                : <Text style={styles.productCardPriceText}>{`${price}€`}</Text>
              }
            </View>}
          {[product.brand != undefined || product.gtin != undefined] && <Text style={styles.productCardManufacturerText}>{product.brand} - {product.gtin}</Text>}
          {currentScanner.displayStock &&
            <View style={styles.inStockWrapper}>
              <Image style={styles.dotWrapper} source={product.stock && product.stock > 0 ? greenDot : redDot} />
              <Text style={styles.inStockText}>{product.stock && product.stock > 0 ? `${I18n.t('In stock')} (${product.stock})` : I18n.t('out of stock')}</Text>
            </View>}
        </View>
        <View style={[styles.sizeContainer, { backgroundColor: Colors.mainColorDark.color }]}>
          <SizeScale caption={"EU"}
            valueCount={5}
            categories={secondarySize ?
              secondarySize < primarySize ? [{ name: secondarySize, value: secondarySize }, { name: primarySize, value: primarySize }]
                : [{ name: primarySize, value: primarySize }, { name: secondarySize, value: secondarySize }]
              : [{ name: '', value: 0 }, { name: primarySize, value: 2 }, { name: '', value: 4 }]}
            values={{ left: 2 }}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default ProductCard;

const styles = StyleSheet.create({
  touchContainer: {
    height: '49%',
    width: '33%',
    backgroundColor: 'transparent',
    paddingTop: '0.5%',
    paddingLeft: '0.5%'
  },
  productCard: {
    height: '65%',
    backgroundColor: 'white'
  },
  soleIcon: {
    width: '7%',
    height: Platform.OS === 'web' ? '25%' : '75%',
    right: Platform.OS === 'web' ? 43 : 25,
    top: Platform.OS === 'web' ? '10%' : '-15%',
    position: 'absolute',
    resizeMode: 'contain',
    zIndex: 10
  },
  customizeIcon: {
    position: 'absolute',
    width: '15%',
    height: '20%',
    top: 10,
    zIndex: 2,
    left: 3,
    resizeMode: 'contain',
  },
  soleIconText: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 12,
    position: 'absolute',
    right: '12%',
    top: '18%',
    zIndex: 11
  },
  imageContainer: {
    flex: 1,
    height: undefined,
    width: undefined,
    resizeMode: 'contain'
  },
  productCardDetails: {
    height: '35%',
    width: '72%',
    flexDirection: 'column',
    paddingLeft: '3%',
    paddingBottom: '3%'
  },
  productCardNameText: {
    flex: 1,
    flexWrap: 'wrap',
    fontFamily: 'Montserrat-Black',
  },
  productCardPriceText: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 14,
    fontWeight: '100',
  },
  widthNameText: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 10,
    fontWeight: '600',
    right: Platform.OS === 'web' ? "-26%" : "-33%",
    position: 'absolute'
  },
  productCardManufacturerText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 10,
    fontWeight: '300',
  },
  sizeContainer: {
    flex: 1,
    flexDirection: 'row',
    position: 'absolute',
    height: '27%',
    width: '26%',
    right: 0,
    bottom: 0
  },
  sizeContainerText: {
    fontFamily: 'Montserrat-Light',
    fontSize: 17,
    color: 'white'
  },
  inStockWrapper: {
    flexDirection: 'row',
    width: 'auto',
    justifyContent: 'flex-start',
    left: -9
  },
  dotWrapper: {
    width: '15%',
    height: '70%',
    alignSelf: 'center',
    resizeMode: 'contain'
  },
  inStockText: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 12,
    fontWeight: '100',
  }

});
