import { Platform, View } from "react-native";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, ImageBackground, StyleSheet, TouchableOpacity, Image, Text, TextInput } from "react-native";
import Swiper from "react-native-web-swiper";
import { background, Icons } from "../../assets";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { useGlobalState } from "../../shared/state";
import { chunk, groupByKey, sleep } from "../../shared/util";
import { Styles, Fonts } from "../../styles";
import ProductCard from "./ProductCard";
import { useNavigation } from '@react-navigation/native';
import { CategoryCardDTO } from "../../shared/model/types";
import { I18N } from "../../shared/util";
import { getMatchedProductsByGTIN, sortProductsByStore } from "../../shared/products";
import CrossProductCard, { CrossProduct } from "./CrossProductCard";

const ProductOverview: React.FC = (route): JSX.Element => {
  const { params } = route.route;
  const [matchedProducts] = useGlobalState("matchedProducts");
  const [modalIsDisplayed] = useGlobalState("modalIsDisplayed");
  const [currentScanner] = useGlobalState("currentScanner");
  const [currentScan] = useGlobalState("currentScan");
  const [currentUser] = useGlobalState("currentUser");
  const [Colors] = useGlobalState('colors');
  const [customerStoreID] = useGlobalState("customerStoreID");

  const [products, setProducts] = useState<any>([]);
  const [productsArrayHolder, setProductsArrayHolder] = useState<any>([]);
  const [productsIndex, setproductsIndex] = useState<number>(0);
  const [swiperForm, setSwiperForm] = useState<number>(0);
  const [swiperCards, setswiperCards] = useState<any>([]);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [noProductFound, setNoProductFound] = useState<boolean | undefined>(false);
  const [language] = useGlobalState("language");
  const [currentMeasurements] = useGlobalState("currentMeasurements");

  const i18n = I18N(language);
  const navigation = useNavigation();
  const categoryAttribute: CategoryCardDTO = params && params.categoryAttribute || null;
  const categoryID: string = params && params.categoryID || null;
  const runningProducts: any = params && params.runningProducts || null;
  const filtredProducts: any = params && params.filtredProducts || null;
  // TEMPORARY SOLUTION FOR DIGITAL-X
  const catalogTitle: string = i18n.t("catalog");
  // const catalogTitle: string = runningProducts ? i18n.t("catalog") : filtredProducts ? "FILTER CATALOG" : "PRE-FILTER CATALOG";
  const shoe_set: any = [];

  const storeID: string = customerStoreID ? customerStoreID : currentScanner["storeid"] || undefined;
  const userWidhtCategory: any = currentMeasurements ? Math.min(currentMeasurements.measurements[0].measurements.width_category, currentMeasurements.measurements[1].measurements.width_category) : 0;
  const crossProduct: CrossProduct =
    currentScanner.catalog && currentScanner.catalog.categories.find((category: any) => category.id.toLowerCase() === categoryID)
      ? currentScanner.catalog.categories.find((category: any) => category.id.toLowerCase() === categoryID).crossProducts
      : undefined;
  const getSwiperCards = (productsArray: any = undefined) => {
    var sortedProduct: any[] = sortProductsByStore(productsArray, storeID, userWidhtCategory);
    chunk(sortedProduct.slice(0, productsIndex + 59), crossProduct ? 5 : 6).map((products: any, i: number) =>
      setswiperCards((prevState: any) => [
        ...prevState,
        <View style={styles.cardWrapper} key={i}>
          {crossProduct && <CrossProductCard crossProduct={crossProduct} />}
          {products.map((product: any, index: number) => {
            if (crossProduct) product.crossProduct = crossProduct;
            return <ProductCard key={index} {...product} />;
          })}
          {i === swiperForm + 9 && renderLoadCard()}
        </View>
      ])
    );
    setLoading(false);
  };

  const getPREFILTREDProducts = () => {
    groupByKey(matchedProducts, "id")[categoryID].map((res: any) => {
      if (categoryAttribute) {
        if (res.attributes === categoryAttribute.id) {
          shoe_set.push(res.products);
        }
      } else {
        shoe_set.push(res.products);
      }
    });
    setProducts(shoe_set);
    setProductsArrayHolder(shoe_set);
  };

  useEffect(() => {
    if (products && products.length > 0 && isLoading) getSwiperCards(products);
  }, [products]);

  useEffect(() => {
    if (productsIndex != 0 && isLoading) getSwiperCards(products);
  }, [productsIndex]);

  useEffect(() => {
    setProducts(runningProducts || filtredProducts || undefined);
    setProductsArrayHolder(runningProducts || filtredProducts || undefined);
  }, []);

  useEffect(() => {
    if (categoryID && matchedProducts) getPREFILTREDProducts();
  }, [categoryID, categoryAttribute]);

  const loadMoreProducts = () => {
    setLoading(true);
    setproductsIndex(productsIndex + 60);
    setSwiperForm(swiperForm + 10);
    setswiperCards([]);
  };

  const renderLoadCard = () => {
    return (
      <TouchableOpacity style={styles.touchContainer} activeOpacity={0.6} onPress={loadMoreProducts}>
        <View style={Styles.card}>
          <View style={styles.loadingCard}>
            <Image source={Icons.loadMoreIcon} style={styles.imageContainer} />
            <Text style={Fonts.textHuge}>{i18n.t("loadMoreProducts")}</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };
  const onchangeText = (e: string) => {
    if (products.filter((product: any) => product.name.toLowerCase().includes(e.toLowerCase())).length > 0) {
      setLoading(true);
      setswiperCards([]);
      setProducts(products.filter((product: any) => product.name.toLowerCase().includes(e.toLowerCase())));
    } else {
      setNoProductFound(true);
    }
  };

  const onSelect = data => {
    setLoading(true);
    var onfid: string = (currentUser && currentUser.email) || (currentUser && currentUser.employeeNumber) || currentScan.customer_uuid;
    getMatchedProductsByGTIN(onfid, data.GTIN)
      .then((res: any) => [setswiperCards([]), setProducts([res])])
      .catch((e: any) => [setLoading(false), setNoProductFound(true)]);
  };

  // TODO6 check not working
  const resetSearch = () => {
    setNoProductFound(false);
    setLoading(true);
    setswiperCards([]);
    // setProducts(productsArrayHolder)
    sleep(10).then(() => getSwiperCards(productsArrayHolder));
  };

  if (isLoading) {
    return (
      <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
        <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true} title={catalogTitle}>
          <View style={styles.loaderWrapper}>
            <View style={styles.loader}>
              <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
              <Text style={[Fonts.textTaller, Fonts.size18]}>{i18n.t("loadProductMessage")}</Text>
            </View>
          </View>
        </LayoutWrapper>
      </ImageBackground>
    );
  } else if (!isLoading && noProductFound)
    return (
      <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
        <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true} title={catalogTitle}>
          <View style={styles.noProductsWrapper}>
            <Image source={Icons.NotFoundImage} style={{ width: "50%", height: "50%", resizeMode: "contain", alignSelf: "center" }} />
            <Text style={styles.loadingText}>{i18n.t("noSearchProductFoundMessage")}</Text>
            <TouchableOpacity style={Styles.submitButtonLight} onPress={() => resetSearch()}>
              <Text style={[styles.buttonText, { ...Colors.black }]}>{i18n.t("make new search")}</Text>
            </TouchableOpacity>
          </View>
        </LayoutWrapper>
      </ImageBackground>
    );
  else if (!isLoading && !noProductFound)
    return (
      <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
        <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true} title={catalogTitle}>
          {!modalIsDisplayed && products.length > 6 && (
            <View style={styles.searchWrapper}>
              <>
                <Image source={Icons.searshLoop} style={styles.loopIcon} />
                <TextInput placeholder={i18n.t("searchPlaceHolder")} onSubmitEditing={(event: any) => onchangeText(event.nativeEvent.text)} style={styles.searchBar} returnKeyType="search" />
              </>
              <TouchableOpacity style={styles.barCodeWrapper} onPress={() => navigation.navigate("BarCodeScanner", { onSelect: onSelect })}>
                <Image source={Icons.barCodeIcon} style={styles.barCodeIcon} />
              </TouchableOpacity>
            </View>
          )}
          <View style={styles.swipeWrapper}>
            <Swiper
              from={swiperForm}
              controlsProps={{
                dotsTouchable: true,
                prevPos: false,
                nextPos: false,
                dotActiveStyle: { backgroundColor: "#3a3a3a" },
                dotsWrapperStyle: { width: 13, height: 13 }
              }}
            >
              {swiperCards}
            </Swiper>
          </View>
        </LayoutWrapper>
      </ImageBackground>
    );
};

export default ProductOverview;

const styles = StyleSheet.create({
  swipeWrapper: {
    flex: 1,
    marginTop: "2.2%"
  },
  cardWrapper: {
    width: "80%",
    height: "90%",
    flexDirection: "row",
    alignSelf: "center",
    flexWrap: "wrap",
    paddingLeft: "0.2%"
  },
  loaderWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  loader: {
    height: "15%",
    width: "100%",
    alignContent: "center",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    paddingLeft: "1%"
  },
  imageContainer: {
    height: "50%",
    width: "50%",
    resizeMode: "contain"
  },
  touchContainer: {
    height: "49%",
    width: "33%",
    backgroundColor: "transparent",
    paddingTop: "0.5%",
    paddingLeft: "0.5%"
  },
  loadingCard: {
    height: "70%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  searchWrapper: {
    width: Platform.OS === "web" ? "25%" : "26%",
    height: "6%",
    padding: 5,
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
    position: "absolute",
    right: Platform.OS === "web" ? 220 : "11.7%",
    top: Platform.OS === "web" ? 30 : 17,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#cacaca",
    backgroundColor: "white",
    zIndex: 10
  },
  searchBar: {
    fontSize: 16,
    width: "75%",
    height: "100%",
    backgroundColor: "white"
  },
  barCodeWrapper: {
    height: "100%",
    width: "12%",
    alignSelf: "center"
  },
  barCodeIcon: {
    resizeMode: "contain",
    height: "100%",
    width: "100%",
    alignSelf: "center"
  },
  loopIcon: {
    resizeMode: "contain",
    height: "85%",
    width: "10%",
    alignSelf: "center"
  },
  noProductsWrapper: {
    width: "100%",
    height: "50%",
    justifyContent: "space-around",
    marginBottom: "15%"
  },
  loadingText: {
    fontFamily: "Montserrat-SemiBold",
    fontSize: 19,
    fontWeight: "600",
    textAlign: "center",
    alignSelf: "center"
  },
  buttonText: {
    ...Fonts.mRegular,
    ...Fonts.size12,
    ...Fonts.center,
    textTransform: "uppercase"
  }
});
