import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Image, ImageBackground, PixelRatio, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { background, leftFoot } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useNavigation } from '@react-navigation/native';
import { Scanfile } from '../../shared/model/types';
import ScannerService from '../../shared/services/scanner-service';
import { useGlobalState } from '../../shared/state';
import { Styles } from '../../styles';
import ScanFailedModal from './ScanFailedModal';
import { I18N } from '../../shared/util';


const ScanLeftFoot: React.FC = (): JSX.Element => {
  const [, setCurrentScreen] = useGlobalState("currentScreen");
  const [currentScanner] = useGlobalState("currentScanner");
  const [newScanState] = useGlobalState("newScanState");
  const [language] = useGlobalState("language");
  const [scanfiles, setScanfiles] = useGlobalState("scanfiles");
  const [currentMeasurements] = useGlobalState("currentMeasurements");

  const [scanInProgress, setScanInProgress] = useState<boolean>(false);
  const [scanProcessResult, setScanProcessResult] = useState<"SUCCESS" | "FAILURE">();
  const [scannerParameter, setScannerParameter] = useState<any>({
    scannedObject: undefined,
    side: undefined,
    returnType: undefined
  });
  const [errorCode, setErrorCode] = useState<string>("");

  const navigation = useNavigation();
  const i18n = I18N(language);

  useEffect(() => {
    getScanParameter();
  }, []);

  useEffect(() => {
    if (scanfiles.length === 1) {
      navigate("ScanRightFoot");
    }
  }, [scanfiles]);

  const navigate = (route: string | undefined, options: any = undefined) => {
    if (route) {
      navigation.navigate(route, options);
      setCurrentScreen(route);
    }
  };

  const reset = () => {
    setScanProcessResult(undefined);
    setScanfiles([]);
    setScanInProgress(false);
  };

  const getScanParameter = () => {
    const mode = newScanState && newScanState.mode ? newScanState.mode.toLowerCase() : "socks";
    setScannerParameter({
      scannedObject: mode,
      side: "left",
      returnType: "base64"
    });
  };

  const getScan = () => {
    if (scannerParameter) {
      setScanInProgress(true);
      ScannerService.getScan(currentScanner['scanner-address'], scannerParameter)
        .then((scanResult: Scanfile) => {

          setScanProcessResult("SUCCESS");
          setScanfiles([
            ...scanfiles,
            {
              side: scanResult.side,
              data: scanResult.data,
              format: scanResult.format,
              condition: "standing",
              raw_measurements: {
                arch_height: scanResult.rawMeasurements.archHeight,
                heel_angle: scanResult.rawMeasurements.heelAngle,
                heel_angle_heel: scanResult.rawMeasurements.heelAngleH,
                heel_angle_tendon: scanResult.rawMeasurements.heelAngleT
              },
              categorized_measurements: {
                arch_height: {
                  category: scanResult.categorizedMeasurements.archHeight.category,
                  value: scanResult.categorizedMeasurements.archHeight.value
                },
                heel_angle: {
                  category: scanResult.categorizedMeasurements.heelAngle.category,
                  value: scanResult.categorizedMeasurements.heelAngle.value
                },
                heel_angle_heel: {
                  category: scanResult.categorizedMeasurements.heelAngleH.category,
                  value: scanResult.categorizedMeasurements.heelAngleH.value
                },
                heel_angle_tendon: {
                  category: scanResult.categorizedMeasurements.heelAngleT.category,
                  value: scanResult.categorizedMeasurements.heelAngleT.value
                },
                foot_type: {
                  category: scanResult.categorizedMeasurements.footType.category,
                  value: scanResult.categorizedMeasurements.footType.value
                }
              },
              insole_recommendation: scanResult.InsoleRecommendation,
              plantar_image: scanResult.plantarImage,
              colored_sole: scanResult.colouredSole
            }
          ]);
        })
        .catch((error: any) => {
          console.log('error', error);
          if (error.response_code === 404) {
            setErrorCode('scannerBusy')
          } else {
            if (Array.isArray(error.message.errors)) {
              setErrorCode(error.message.errors[0].error_code)
            } else if (error.message && error.message.includes('foam')) {
              setErrorCode('foamBoxNotImplemented')
            } else {
              setErrorCode('genericError')
            }
          }
          setScanInProgress(false);
          setScanProcessResult('FAILURE');
        });
    }
  };

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} blurRadius={15}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={false} showMenuButtons={false} hideNewScanButton hideLeftButton showBackButton={scanInProgress ? false : true}>
        <View style={styles.contentWrapper}>
          {scanInProgress && (
            <>
              <View style={styles.processingWrapper}>
                <Text style={styles.processingText}>{i18n.t("scan.inProgress")}</Text>
                <ActivityIndicator size="large" color={'#2bc6ff'} />
              </View>
            </>
          )}
          {!scanInProgress && (!scanProcessResult || scanProcessResult !== "FAILURE") && (
            <>
              <View style={styles.headerRow}>
                <Text style={styles.headerText}>{i18n.t("scan.captionLeft")}</Text>
              </View>
              <TouchableOpacity style={styles.contentBox} activeOpacity={0.6} onPress={getScan}>
                <View style={styles.captionContainer}>
                  <Text style={styles.start}>{i18n.t("scan.start")}</Text>
                </View>
                <View style={styles.imageContainer}>
                  <Image source={leftFoot} style={styles.image} />
                </View>
                <View style={styles.captionContainer}>
                  <Text style={styles.caption}>{i18n.t("scan.left")}</Text>
                </View>
              </TouchableOpacity>
            </>
          )}
          {!scanInProgress && scanProcessResult && scanProcessResult === "FAILURE" && (
            <>
              <ScanFailedModal errorCode={errorCode} onScanAgain={() => reset()} onCancel={() => navigate(currentMeasurements ? "Measurements" : "Home")} />
            </>
          )}
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default ScanLeftFoot;

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerRow: {
    maxHeight: '15%',
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerText: {
     fontFamily: 'Montserrat-Black',
fontSize: 21,
fontWeight: '900',
    color: '#3a3a3a',
    marginTop: '1.5%'
  },
  contentBox: {
    backgroundColor: '#ffffff',
    opacity: .9,
    width: '40%',
    maxHeight: '75%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12 / PixelRatio.get(),
    margin: '1.5%',
    shadowColor: '#000',
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: .16,
    shadowRadius: 4 / PixelRatio.get(),
    elevation: 1
  },
  imageContainer: {
    height: '65%',
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'  },
  image: {
    height: '100%',
    width: '50%',
    maxWidth: '75%',
    resizeMode: 'contain',
    marginTop: '2%'
  },
  captionContainer: {
    height: '10%',
    marginTop: '7%'
  },
  caption: {
    fontFamily: 'Montserrat-Black',
    fontSize: 18,
    color: '#3a3a3a'
  },
  start: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 18,
    color: '#3a3a3a'
  },
  processingWrapper: {
    minHeight: '90%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  processingText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 30,
    marginBottom: 15
  },
  checkmark: {
    maxHeight: '75%',
    maxWidth: '75%',
    resizeMode: 'contain',
  }
});
