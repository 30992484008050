import ProductService from "../services/products-service";
import { getLastWidhthOrder } from "../util";

export const getMatchedproducts = (
  setAllProducts: (product: any) => void,
  setMatchedProducts: (product: any) => void,
  setInitialMatchedProducts: (product: any) => void,
  lastwidthcategoryMode: string,
  customer_uuid: string,
  attributeName: any,
  gender?: string,
  safetyClass?: any,
  storeID?: string,
  showPartnerProducts?: boolean,
  showZeroStockProducts?: boolean,
  onComplete?: () => void
) => {
  var filtredArray: any[] = [];
  const data = showPartnerProducts
    ? { onefid: customer_uuid, lastwidthcategory_mode: lastwidthcategoryMode, gender: ["u", gender] }
    : { onefid: customer_uuid, lastwidthcategory_mode: lastwidthcategoryMode, gender: ["u", gender], store_id: storeID };
  ProductService.getMatchingProducts(data)
    .then((response: any) => {
      //throw away empty and duplicated gtins
      let matchedProductGtins = response.results.matching.filter((p1: any) => !!p1.gtin).map((p1: any) => (p1.gtin ? p1.gtin : undefined));
      setAllProducts(response.results.products);

      response.results.products.map((product: any) => {
        product.details.map((detail: any) => {
          if (matchedProductGtins.includes(detail.gtin)) {
            // TODO: remove when checked everything is working as expected
            // matchedProductGtins.splice(matchedProductGtins.indexOf(detail.gtin), 1);
            let secondary_eu: any = response.results.matching.find((product: any) => product.gtin === detail.gtin).secondary_eu;
            let secondary_uk: any = response.results.matching.find((product: any) => product.gtin === detail.gtin).secondary_uk;
            let secondary_us: any = response.results.matching.find((product: any) => product.gtin === detail.gtin).secondary_us;
            console.log("product:", product.category_info.id);
            filtredArray.push({
              id: product.category_info.id,
              name: product.category_info.name,
              attributes: product[attributeName],
              products: {
                id: product.id,
                name: product.name,
                categoryId: product.category_info.id,
                categoryName: product.category_info.name,
                brand: product.brand,
                gtin: detail.gtin,
                mpn: product.mpn,
                price: detail.price,
                salePrice: detail.sale_price,
                color: product.color,
                storeID: detail.store_id,
                stock: detail.stock,
                warehouseBin: detail.warehouse_bin,
                leatherType: product.leather_type,
                support: product.support,
                underground: product.underground,
                safety_class: product.safety_class,
                suitableOrthoAdaption: product.suitable_ortho_adaption,
                suitableOrthoInsole: product.suitable_ortho_insole,
                suitableStandardInsole: product.suitable_standard_insole,
                productGender: product.gender,
                application: product.application,
                ...(product.images[0] && {image_url: product.images[0].original}),
                ...(product.images[0] && {image_url: product.images[0].thumb}),
                size: {
                  eu: response.results.matching.find((product: any) => product.gtin === detail.gtin).eu,
                  uk: response.results.matching.find((product: any) => product.gtin === detail.gtin).uk,
                  us: response.results.matching.find((product: any) => product.gtin === detail.gtin).us,
                  ...(secondary_eu && { secondary_eu: secondary_eu }),
                  ...(secondary_uk && { secondary_uk: secondary_uk }),
                  ...(secondary_us && { secondary_us: secondary_us })
                },
                ...(product.images.length > 0 && { image_url: product.images[0].original }),
                ...(product.images.length > 0 && { thumb_url: product.images[0].thumb }),
                description: product.description,
                last_width_category: product.last_width_category,
                width_name: product.width_name,
                msi: product.msi,
                leading_system: response.results.matching.find((product: any) => product.gtin === detail.gtin).leading_system,
                ...response.results.matching.find((product: any) => product.gtin === detail.gtin).leading_system
              }
            });
          }
        });
      });

      // remove duplicated products based on gtin
      // var uniqueMatchedProducts: any[] = filtredArray.reduce((unique, o) => {
      //   if (!unique.some(obj => obj.products.gtin === o.products.gtin)) {
      //     unique.push(o);
      //   }
      //   return unique;
      // }, []);

      // filter matched products based on user safety class
      if (safetyClass && attributeName === "safety_class") filtredArray = filtredArray.filter((p: any) => p.attributes === safetyClass);

      // filter matched products based on storeID
      if (!showPartnerProducts && storeID) filtredArray = filtredArray.filter((p: any) => p.products.storeID === storeID);
      // filter matched products based on stock
      if (!showZeroStockProducts) filtredArray = filtredArray.filter((p: any) => p.products.stock !== 0)

      console.log("MatchedProducts", filtredArray.length);
      setMatchedProducts(filtredArray);
      setInitialMatchedProducts(filtredArray);
      // Call the onComplete callback if provided
      if (onComplete) onComplete();
    })

    .catch(e => {
      setMatchedProducts([]);
      setInitialMatchedProducts([]);
      console.log("ERROR getMatchingProducts2: ", e);
      // Call the onComplete callback in case of an error as well
      if (onComplete) onComplete();
    });
};

export const getMatchedProductsByGTIN = (onefid: string, gtin: string): any => {
  return ProductService.getMatchingProductsByGTIN(onefid, gtin).then((res: any) => {
    return {
      id: res.matching_id,
      gtin: res.gtin,
      name: res.product_name,
      brand: res.brand,
      description: res.description,
      image_url: res.image_url,
      thumb_url: res.thumb_url,
      last_width_category: res.last_width_category,
      leading_system: res.leading_system,
      gender: res.gender,
      size: {
        eu: res.eu,
        uk: res.uk,
        us: res.us,
        secondary_eu: res.secondary_eu,
        secondary_uk: res.secondary_uk,
        secondary_us: res.secondary_us
      }
    };
  });
};

export const sortProductsByStore = (matchedProducts: any[], storeID: any, widthCategory: any) => {
  const LastWidhthOrderArray = getLastWidhthOrder(widthCategory);
  var productsInStock: any[] = [];
  var productsOutOfStock: any[] = [];

  matchedProducts.map((product: any) => {
    product.storeID === storeID && product.stock !== 0 ? productsInStock.push(product) : productsOutOfStock.push(product);
  });

  productsInStock.sort((p1, p2: any) => LastWidhthOrderArray.indexOf(p1.last_width_category) - LastWidhthOrderArray.indexOf(p2.last_width_category));

  productsOutOfStock.sort((p1, p2: any) => LastWidhthOrderArray.indexOf(p1.last_width_category) - LastWidhthOrderArray.indexOf(p2.last_width_category));

  return productsInStock.concat(productsOutOfStock);
};
